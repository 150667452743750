import { ProductListController } from './product-list';
import { OrderList } from '../models/order-list';
import { ProductList } from '../models/product-list';

export class OrderListController {
    public static getFormattedData(listData: any): OrderList[] {
        const dataModel: OrderList[] = [];
        let imageArray: ProductList[] = [];
        if (listData) {
            for (const items of listData.orders) {
                const listItems: OrderList = {
                    orderId: items.orderId ? items.orderId : '',
                    orderDate: items.date ? items.date.split(' ')[0] : '',
                    orderStatus: items.status ? items.status : '',
                    shippingAddress: (items.address && items.address.formattedAddress) ? items.address.formattedAddress : '',
                    aocStatus: items.aocStatus ? items.aocStatus : '',
                    id: items.id ? items.id : '',
                    productList: items.products ? ProductListController.getFormattedData(items.products) : [],
                    aocId: (items.aocDetails && items.aocDetails.id) ? items.aocDetails.id : ''
                };
                dataModel.push(listItems);
            }
            imageArray = listData.productImages ? ProductListController.getFormattedData(listData.productImages) : [];
        }
        dataModel.forEach(order => {
            order.productList.forEach(product => {
                imageArray.forEach(image => {
                    if (image.productId === product.productId) {
                        product.imageUrl = image.imageUrl;
                    }
                });
            });
        });
        return dataModel;
    }
}

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpService } from '../core/services/interceptors/http.service';

@Injectable({
  providedIn: 'root'
})
export class ProductListService {

  constructor(private httpService: HttpService) { }

  getProductList(listInfo: any, userId: any) {
    let serviceUrl = environment.endpoints.productList.endpoint;
    const serviceUrlObj = {
      endpoint: ''
    };
    serviceUrl = serviceUrl += '/' + userId;
    listInfo.size = listInfo.size ? listInfo.size : 10;
    listInfo.page = listInfo.page ? listInfo.page : 1;
    serviceUrl += '?limit=' + listInfo.size;
    serviceUrl += '&offset=' + listInfo.page;
    serviceUrlObj.endpoint = serviceUrl;
    return this.httpService.httpRequest(serviceUrlObj, 'GET', {}, true, {});
  }

  placeProductOrder(requestData: {}) {
    return this.httpService.httpRequest(environment.endpoints.placeOrder, 'POST', requestData, true, {});
  }
}

import { Injectable } from '@angular/core';
import { HttpService } from '../core/services/interceptors/http.service';
import { environment } from 'src/environments/environment';
import { Pagination } from '../models/order-list';

@Injectable({
  providedIn: 'root'
})
export class OrderListService {

  constructor(
    private httpService: HttpService
  ) { }

  /**
   * get order list
   * @params listInfo, userId
   */
  getOrderList(listInfo: Pagination, userId: string) {
    let serviceUrl = environment.endpoints.orderList.endpoint;
    const serviceUrlObj = {
      endpoint: ''
    };
    listInfo.size = listInfo.size ? listInfo.size : 10;
    listInfo.page = listInfo.page ? listInfo.page : 1;
    serviceUrl = serviceUrl += '/' + userId + '?limit=' + listInfo.size + '&offset=' + listInfo.page;
    serviceUrlObj.endpoint = serviceUrl;
    return this.httpService.httpRequest(serviceUrlObj, 'GET', {}, true, {});
  }

  /**
   * get details of given order
   * @params orderId
   */
  getOrder(orderId: string) {
    let serviceUrl = environment.endpoints.orderList.endpoint;
    const serviceUrlObj = {
      endpoint: ''
    };
    serviceUrl = serviceUrl += '?ids=' + orderId;
    serviceUrlObj.endpoint = serviceUrl;
    return this.httpService.httpRequest(serviceUrlObj, 'GET', {}, true, {});
  }
}

import { ProductList } from '../models/product-list';

export class ProductListController {
    public static getFormattedData(listData: any): ProductList[] {
        const dataModel: ProductList[] = [];
        for (const items of listData) {
            const listItems: ProductList = {
                productName: items.name ? items.name : '',
                productId: items.id ? items.id : '',
                dosageVarieties: items.dosage ? items.dosage : '',
                proprietaryName: items.proprietaryName ? items.proprietaryName : '',
                ndc: items.ndc ? items.ndc : '',
                manufacturerName: items.manufacturerName ? items.manufacturerName : '',
                description: items.description ? items.description : '',
                quantity: (items.productRule && items.productRule.remainingQuantity) ? items.productRule.remainingQuantity : 0,
                imageUrl: items.imageUrl ? items.imageUrl : '', //  null for order-list service
                sampleCount: (items.productRule && items.productRule.remainingQuantity) ? items.productRule.remainingQuantity : 0,
                sampleFrequency: items.sampleFrequency ? items.sampleFrequency : '',
                moreInfo: items.moreInfo ? items.moreInfo : '',
                orderQuantity: items.orderQuantity ? items.orderQuantity : '',
                ruleFrequency: (items.productRule && items.productRule.ruleFrequency) ? items.productRule.ruleFrequency : '',
                orderedQuantity: items.quantity ? items.quantity : 0,
                productStatus: items.status ? items.status : '',
                trackingNumber: items.trackingNumber ? items.trackingNumber : '',
                attachment:items.attachment?items.attachment:''
            };
            dataModel.push(listItems);
        }
        return dataModel;
    }
}
